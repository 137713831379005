import { ComponentPropsWithoutRef, Fragment, ReactNode } from 'react';
import cn from 'classnames';
import { Settings } from 'react-slick';
import SlickSlider from '../../../../modules/react-slick';
import s from './Slider.module.scss';

export type SliderSettings = Omit<Settings, 'asNavFor'>;

// See https://react-slick.neostack.com/docs/api
const defaultSettings: SliderSettings = {
  dots: false,
  infinite: true,
};

export interface SliderProps<T>
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'> {
  slides: Array<T>;
  renderSlide: (slide: T, index: number) => ReactNode;
  settings?: SliderSettings;
  setRef?: (slider: any) => void;
  asNavFor?: Settings['asNavFor'];
}

export default function Slider<T>({
  slides,
  renderSlide,
  settings: customSettings,
  setRef,
  asNavFor,
  className,
}: SliderProps<T>) {
  const settings = Object.assign({}, defaultSettings, customSettings);

  return (
    <SlickSlider
      ref={setRef}
      asNavFor={asNavFor}
      {...settings}
      className={cn(s.wrapper, className)}
    >
      {slides.map((slide, index) => (
        <Fragment key={index}>{renderSlide(slide, index)}</Fragment>
      ))}
    </SlickSlider>
  );
}
