import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { getImgixUrl } from '../../../../helpers';
import { useRootSelector } from '../../../../store/hooks';
import { useShowBackgroundVideo } from '../HeroModule/hooks/useShowBackgroundVideo';
import { selectTenantConfig } from '../../../../store/features/tenantConfig';
import { CallToAction, ImageTag, SiteBuilderModule } from '../sharedComponents';
import { BackgroundSlider } from '../../../hub/shared/siteBuilder/modules/HeroModule/BackgroundSlider';
import HeroModuleSummary from '../HeroModule/innerComponents/HeroModuleSummary';
import {
  BackgroundVideo,
  HeroModulePreTitle,
  HeroModuleSubTitle,
  HeroModuleText,
  HeroModuleTitle,
} from '../HeroModule/innerComponents';
import {
  BrandLogoWrapper,
  CallToActionsWrapper,
  ContentWrapper,
  LanguageSwitcherWrapper,
  Wrapper,
} from './styles';
import LanguageSwitcher from './LanguageSwitcher';

export const DisplayType = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  MULTIPLE_IMAGES: 'MULTIPLE_IMAGES',
};

export default function HeroBannerModule(props) {
  const {
    section: {
      displayType,
      subText: preTitle,
      valueProposition: title,
      titleSize,
      subtitle: subTitle,
      text,
      primaryLink: primaryCTAConfig = {},
      secondaryLink: secondaryCTAConfig = {},
      displayDataAndVenueInfo,
      background,
      carouselImages: slides,
      carouselOptions: animation,
      videoId,
      videoSource,
      playVideoOnMobile,
    },
    brandLogo,
    brandLogoSize,
  } = props;

  const { id: tenantId } = useRootSelector(selectTenantConfig);
  const { siteBuilderModules } = useContext(ThemeContext);
  const { heroBannerModule } = siteBuilderModules || {};

  const showBackgroundVideo = useShowBackgroundVideo(
    videoId,
    playVideoOnMobile,
  );

  const moduleTheme = {
    vStyles: heroBannerModule.visualStyles,
    dStyles: heroBannerModule.displayStyles,
  };
  const backgroundImage = getImgixUrl(tenantId, background?.path);

  return (
    <SiteBuilderModule section={props.section} moduleTheme={moduleTheme}>
      <Wrapper image={backgroundImage}>
        {displayType === DisplayType.MULTIPLE_IMAGES && (
          <BackgroundSlider slides={slides} animation={animation} />
        )}
        {displayType === DisplayType.VIDEO && showBackgroundVideo && (
          <BackgroundVideo
            videoId={videoId}
            videoSource={videoSource}
            withOverlay
          />
        )}
        <LanguageSwitcherWrapper>
          <LanguageSwitcher />
        </LanguageSwitcherWrapper>
        <ContentWrapper>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-10">
                {brandLogo && (
                  <BrandLogoWrapper size={brandLogoSize}>
                    <ImageTag src={brandLogo} lazy={false} />
                  </BrandLogoWrapper>
                )}
                {displayDataAndVenueInfo && <HeroModuleSummary />}
                <HeroModulePreTitle text={preTitle} />
                <HeroModuleTitle as={titleSize} text={title} />
                <HeroModuleSubTitle text={subTitle} />
                <HeroModuleText text={text} />
                <CallToActionsWrapper>
                  {primaryCTAConfig?.shown && (
                    <CallToAction look="accent" ctaConfig={primaryCTAConfig} />
                  )}
                  {secondaryCTAConfig?.shown && (
                    <CallToAction
                      look="hollow"
                      ctaConfig={secondaryCTAConfig}
                    />
                  )}
                </CallToActionsWrapper>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </Wrapper>
    </SiteBuilderModule>
  );
}
