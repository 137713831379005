import { useCallback, useMemo, ChangeEventHandler } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { localizePath } from '../../../../../helpers/locale';
import { useRootSelector } from '../../../../../store/hooks';
import { selectOptions } from '../../../../../store/features/options';
import { Anchor } from '../../../../shared/Anchor';
import s from './LanguageSwitcher.module.scss';

export default function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const {
    locales: { primary, secondary },
  } = useRootSelector(selectOptions);

  const locales = useMemo(() => {
    return [
      primary,
      ...secondary
        .filter((locale) => locale.live)
        .map((locale) => locale.locale),
    ];
  }, [primary, secondary]);

  const handleChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const locale = e.target.value;
      document.location.href = localizePath(pathname, locale, primary);
    },
    [pathname, primary],
  );

  if (locales.length <= 1) {
    return null;
  }

  return (
    <>
      <div className={s.select}>
        <select defaultValue={i18n.language} onChange={handleChange}>
          {locales.map((locale) => (
            <option key={locale} value={locale}>
              {t(`global.languages.${locale}.label`)}
            </option>
          ))}
        </select>
      </div>
      <nav className={s.nav}>
        <ul>
          {locales.map((locale) => (
            <li key={locale}>
              <Anchor
                to={localizePath(pathname, locale, primary)}
                className={s.anchor}
              >
                {t(`global.languages.${locale}.label`)}
              </Anchor>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}
