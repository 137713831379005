import styled from 'styled-components';
import { renderStyledElementStyles } from '../../helpers';

export const Wrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  background: center no-repeat;
  background-size: cover;
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.vStyles)}
  ${({ theme }) => renderStyledElementStyles(theme, theme.module.dStyles)}
`;

export const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const LanguageSwitcherWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin: 0 20px 70px;

  @media screen and (min-width: 1200px) {
    position: absolute;
    top: 130px;
    right: 20px;
    margin-bottom: 0;
  }

  &:empty {
    display: none;
  }
`;

export const CallToActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  &:empty {
    display: none;
  }
`;

export const BrandLogoWrapper = styled.div`
  img {
    ${({ theme }) =>
      renderStyledElementStyles(theme, theme.module.vStyles.elements.logo)}
    ${({ theme, size = 1 }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.logo.size[size],
      )}
  }
`;
