import { useMemo } from 'react';
import { UploadedImage } from '../../../../../../../types';
import ImageTag from '../../../../../../shared/ImageTag/ImageTag';
import Slider, { SliderSettings } from '../../../../Slider';
import s from './BackgroundSlider.module.scss';

const carouselSettings: SliderSettings = {
  arrows: false,
  dots: false,
  autoplay: true,
  autoplaySpeed: 5000,
  useCSS: false,
};

const customCarouselSettings = {
  NONE: {
    ...carouselSettings,
  },
  SLIDE: {
    ...carouselSettings,
    useCSS: true,
  },
  FADE: {
    ...carouselSettings,
    useCSS: true,
    fade: true,
  },
};

export interface BackgroundSliderProps {
  slides: Array<{
    image: UploadedImage;
    alt: string;
  }>;
  animation: keyof typeof customCarouselSettings;
}

export function BackgroundSlider(props: BackgroundSliderProps) {
  const { slides, animation } = props;

  const settings = useMemo(
    () => ({
      ...customCarouselSettings[animation],
    }),
    [animation],
  );

  return (
    <div className={s.wrapper} data-testid="background-slider">
      <div className={s.slider}>
        <Slider
          slides={slides}
          renderSlide={(slide) => (
            <ImageTag
              src={slide.image.path}
              alt={slide.alt}
              lazy={false}
              className={s.slide}
            />
          )}
          settings={settings}
        />
      </div>
    </div>
  );
}
